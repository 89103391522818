import { Record } from "immutable";
import { assign } from "lodash";
import { INIT, LOADING, SUCCESS, ERROR } from "../../constants/phase";

// import { fromPromise } from 'rxjs/observable/fromPromise'
import { from } from "rxjs";

import { of } from "rxjs";
import { mergeMap, flatMap, catchError } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";

import * as api from "./api";
import { Statistic } from "antd";

/***********************************
 * Action Types
***********/
// const cookies = new Cookies()

// PHASE START
export const LOGIN = "home/LOGIN"
export const USER = "home/USER"
export const UPDATEUSER = "home/USER"
export const QUIZ = "home/QUIZ"
export const TRAININGVIDEO = "home/TRAININGVIDEO"
export const OTP = "home/OTP"
export const VERIFYOTP = "home/VERIFYOTP"
export const SIGNUP = "home/SIGNUP"
export const SUBMIT = "home/SUBMIT"

export const CHECKIN = "home/CHECKIN"

export const STATISTICS_TAGGR = "home/STATISTICS_TAGGR"

export const CONTACT_SUPPORT = "home/CONTACT_SUPPORT";

//////////////////////////////////////()
export const GETLOTS = "home/GETLOTS"
export const GETLOTBYID = "home/GETLOTBYID"

/////////////////////////////////////////()

export const FORGOTPASSW = "home/FORGOTPASSW"
export const NEWPASSWORD = "home/NEWPASSWORD"
export const CREATEPASSWORD = "home/CREATEPASSWORD"
export const EDITPROFILE = "home/EDITPROFILE"
export const TAGGR_DASHBOARD_LOGIN = "home/TAGGR_DASHBOARD_LOGIN";
export const TAGGR_DASHBOARD_LOGIN_ERROR = "home/TAGGR_DASHBOARD_LOGIN_ERROR";
export const TAGGR_DASHBOARD_LOGIN_SUCCESS = "home/TAGGR_DASHBOARD_LOGIN_SUCCESS";
export const ONBOARD_TAGGR_ON_STRIPE = "home/ONBOARD_TAGGR_ON_STRIPE";
export const CONNECT_WITH_STRIPE = "home/CONNECT_WITH_STRIPE";
export const CREATE_CITATION = "home/CREATE_CITATION";

export const MYCITATIONINFO = "home/MYCITATIONINFO";

export const GET_TIRE_TAG_SERIAL_NUMBERS = "home/GET_TIRE_TAG_SERIAL_NUMBERS";
export const UPDATE_TIRE_TAG_SERIAL_NUMBERS = "home/UPDATE_TIRE_TAG_SERIAL_NUMBERS";
export const GET_TIRE_TAG_ACTIVATION_NUMBER = "home/GET_TIRE_TAG_ACTIVATION_NUMBER";
export const END_SESSION = "home/END_SESSION";
export const GET_LICENSE_PLATE_STATUS = "home/GET_LICENSE_PLATE_STATUS";

export const INIT_LOGIN_PHASE = "home/INIT_LOGIN_PHASE";

export const SEARCH_FOR_CITATIONS = "home/SEARCH_FOR_CITATIONS";

export const MAIL_IN = "home/MAIL_IN";
export const GET_SURVEY_BY_USER = "home/GET_SURVEY_BY_USER";

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  loginPhase: INIT,
  loginData: {},
  lots: [],
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ""; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

// export const logins = (value) => {
//   return {
//     type: LOGIN,
//     payload: api.logins(value),
//   };
// };

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    case TAGGR_DASHBOARD_LOGIN: {
      return state.set("loginPhase", LOADING);
    }

    case TAGGR_DASHBOARD_LOGIN_SUCCESS: {
      const { payload } = action;
      return state.set("loginPhase", SUCCESS).set("loginData", payload.results);
    }

    case TAGGR_DASHBOARD_LOGIN_ERROR: {
      return state.set("loginPhase", ERROR);
    }

    case INIT_LOGIN_PHASE: {
      return state.set("loginOtpPhase", INIT);
    }
    //ddddd
    case GETLOTS: {
      const { payload } = action;
      return state.set("lots", payload);
    }
    //ddddd
    /////////////////////////////////
    // case GETLOTBYID: {
    //   const { payload } = action;
    //   return state.set("lots", payload);    
    // }
    /////////////////////

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const logins = (value) => {
  return {
    type: LOGIN,
    payload: api.logins(value),
  };
}
export const user = (value) => {
  return {
    type: user,
    payload: api.user(value),
  };
}
export const updateUser = (id, value) => {
  return {
    type: UPDATEUSER,
    payload: api.updateUser(id, value),
  };
}
export const trainingVideo = (value) => {
  return {
    type: trainingVideo,
    payload: api.trainingVideo(value),
  };
}
export const quiz = (value) => {
  return {
    type: QUIZ,
    payload: api.quiz(value),
  };
}
export const otp = (value) => {
  return {
    type: OTP,
    payload: api.otp(value),
  };
}
export const verifyotp = (value) => {
  return {
    type: VERIFYOTP,
    payload: api.verifyotp(value),
  };
}
export const signup = (value) => {
  return {
    type: SIGNUP,
    payload: api.signup(value),
  };
}
export const submit = (value) => {
  return {
    type: SUBMIT,
    payload: api.submit(value),
  };
}
export const forgotpassw = (value) => {
  return {
    type: FORGOTPASSW,
    payload: api.forgotpassw(value),
  };
}
export const newpassword = (value) => {
  return {
    type: NEWPASSWORD,
    payload: api.newpassword(value),
  };
}

export const createPasswordForTaggr = (value) => {
  return {
    type: CREATEPASSWORD,
    payload: api.createPasswordForTaggr(value),
  };
}

export const editprofile = (value) => {
  return {
    type: EDITPROFILE,
    payload: api.editprofile(value),
  };
}
export const resetLoginPhase = (value) => {
  return {
    type: INIT_LOGIN_PHASE,
  };
};

export const getlots = (city) => {
  return {
    type: GETLOTS,
    payload: api.getlots(city),
  };
}

export const getLotById = (id) => {
  // console.log(id, "<<<<<id in duck")
  return {
    type: GETLOTBYID,
    payload: api.getLotById(id)
  };
}

/**
 * CONNECT WITH STRIPE
 */
export const connectWithStripe = value => {
  return {
    type: CONNECT_WITH_STRIPE,
    payload: api.connectWithStripe(value)
  }
}

export const connectWithStripeExpress = value => {
  return {
    type: CONNECT_WITH_STRIPE,
    payload: api.connectWithStripeExpress(value)
  }
}

export const onboardTaggrOnStripe = ({ isConnecting, account }) => {
  return {
    type: ONBOARD_TAGGR_ON_STRIPE,
    payload: api.onboardTaggrOnStripe(isConnecting, account)
  };
}

export const checkin = (id) => {
  return {
    type: CHECKIN,
    payload: api.checkin(id)
  };
}

/*contact support */
export const contactsupport = value => {
  return {
    type: CONTACT_SUPPORT,
    payload: api.contactsupport(value)
  }
}

// create citation
export const createCitation = value => {
  return {
    type: CREATE_CITATION,
    payload: api.createCitation(value)
  }
}

// GET TIRE TAG SERIAL NUMBERS
export const getTireTagSerialNumbers = value => {
  return {
    type: GET_TIRE_TAG_SERIAL_NUMBERS,
    payload: api.getTireTagSerialNumbers(value)
  }
}

// UPDATE TIRE TAG SERIAL NUMBER
export const updateTireTagStatus = (tiretag_id, value) => {
  return {
    type: UPDATE_TIRE_TAG_SERIAL_NUMBERS,
    payload: api.updateTireTagStatus(tiretag_id, value)
  }
}
// END SESSION
export const end_session = (lotId, value) => {
  return {
    type: END_SESSION,
    payload: api.end_session(lotId, value)
  }
}

// GET TIRE TAG SERIAL NUMBER
export const getTireTagActvationNumber  =(tagId) => {
  return {
    type: GET_TIRE_TAG_ACTIVATION_NUMBER,
    payload: api.getTireTagActvationNumber(tagId)
  }
}

//MY CITATIONS INFORMATION

export const myCitationinfo  =(value) => {
  return {
    type: MYCITATIONINFO,
    payload: api.myCitationinfo(value)
  }
}

export const searchCitations = (searchType, query) => {
  return {
    type: SEARCH_FOR_CITATIONS,
    payload: api.searchCitations(searchType, query)
  }
}

export const get_license_plate_status  =(value) => {
  return {
    type: GET_LICENSE_PLATE_STATUS,
    payload: api.get_license_plate_status(value)
  }
}

//STATISTICS OF TAGGR
export const statistics_taggr  =(value) => {
  return {
    type: STATISTICS_TAGGR,
    payload: api.statistics_taggr(value)
  }
}

//Mail-In 

export const Mail_In = (value) => {
  return {
    type: MAIL_IN,
    payload: api.Mail_In(value)
  }
}

// survey
export const getSurveyByUser = (value) => {
  return {
    type: GET_SURVEY_BY_USER,
    payload: api.getSurveyByUser(value)
  }
}



/*********************************
 * global states for alert and loading 
 **********************************/
const initialState = {
  isLoading: false
}

export const loadingReducer = (state = initialState, action) => {

  if (action.type === 'setLoading') {

    return {
      ...state,
      isLoading: action.payload
    }
  }
  else {
    return state
  }
}


const alertInitialState = {
  message: "",
  status: "hide"
}

export const alertReducer = (state = alertInitialState, action) => {

  if (action.type === 'setAlert') {

    return {
      ...state,
      message: action.payload.message,
      status: action.payload.status
    }
  }
  else {
    return state
  }
}


/***********************************
 * Epics
 ***********************************/
const loginEpic = (action$) =>
  action$.pipe(
    ofType(TAGGR_DASHBOARD_LOGIN),
    mergeMap((action) => {
      return from(api.logins(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: TAGGR_DASHBOARD_LOGIN_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: TAGGR_DASHBOARD_LOGIN_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

export const taggrEpic = combineEpics(loginEpic);
